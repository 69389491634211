import React, { useEffect } from 'react'
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import TextCaption from '../../Components/TextCaption/TextCaption';
import ThreeDCarousel from '../../Components/CircleCarousel/CircleCarousel';
import ThreeDImageCarousel from '../../Components/CircleCarousel/CircleCarousel';
import imgElectraMiningSuccess from '../../assets/news-events/ElectraMiningSuccess/GroupImage.jpg';
import imgElectraMiningSuccess2 from '../../assets/news-events/ElectraMiningSuccess/ExhibitionShowcase.jpg';
import imgElectraMiningSuccess3 from '../../assets/news-events/ElectraMiningSuccess/Stock.jpg';
import imgElectraMiningSuccess4 from '../../assets/news-events/ElectraMiningSuccess/Picture1.jpg';
import imgElectraMiningSuccess5 from '../../assets/news-events/ElectraMiningSuccess/Picture2.jpg';
import imgElectraMiningSuccess6 from '../../assets/news-events/ElectraMiningSuccess/Picture3.jpg';
import imgElectraMiningSuccess7 from '../../assets/news-events/ElectraMiningSuccess/Picture4.jpg';

const ElectraMiningSuccess = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const carouselData = [
        {
            image: imgElectraMiningSuccess,
        },
        {
            image: imgElectraMiningSuccess2,
        },
        {
            image: imgElectraMiningSuccess3,
        },
        {
            image: imgElectraMiningSuccess4,
        },
        {
            image: imgElectraMiningSuccess5,
        },
        {
            image: imgElectraMiningSuccess6,
        },
        {
            image: imgElectraMiningSuccess7,
        },
        // Add more items here
    ];

    return (
        <div>
            <ImageDescription
                title={"Electra Mining Africa 2024: A Resounding Success for Industry Leaders"}
                imageSrc={imgElectraMiningSuccess}
                paragraphs={["Since its inception in 1972, Electra Mining Africa has established itself as the premier platform for businesses in mining, electrical, automation, manufacturing, power, and transport to connect and thrive. As the largest trade show in Southern Africa, Electra Mining Africa continues to set the standard for industry excellence.",
                    "This year’s event was no exception, attracting over 39,000 trade visitors and featuring more than 950 exhibitors across five action-packed days. The show delivered high-quality leads and countless networking opportunities, solidifying its reputation as the event where business meets opportunity."
                ]}
            />

            <br />
            <ImageDescription
                imageSrc={imgElectraMiningSuccess2}
                paragraphs={["As an exhibitor, we were thrilled to showcase our newest addition to our field service vehicle fleet. Fully equipped with the latest technologies, this vehicle is designed to provide solutions and minimize downtime for all our customers. Visitors had the opportunity to personally experience German technology and innovations at its best with this new addition to our fleet.",
                    "In addition to our advanced field service vehicle, we also showcased our state-of-the-art on-site workshops. These workshops drew significant attention from some of the top role players in the mining industry, highlighting our commitment to delivering cutting-edge solutions and services.",
                    "With this exhibition we have solidified our place as one of the leading fluid technology partners in South Africa and we were thrilled to meet and connect with both current and new customers. We are delighted to have them on board and look forward to fostering these valuable relationships."
                ]}
            />
            <ThreeDImageCarousel items={carouselData} />

        </div>
    )
}

export default ElectraMiningSuccess