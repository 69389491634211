import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './MainNavbarMenu.css';
import logo from '../../assets/logo.svg';
import { FaMapMarkerAlt, FaPhone, FaLanguage, FaCode, FaShoppingCart } from 'react-icons/fa'; // Importing icons




function MainNavbarMenu() {

  const [isSticky, setIsSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownClose = () => {
    setExpanded(false);

  };


  return (
    <Navbar className="sec-navbar" bg="light" expand="lg" expanded={expanded}>
      <Navbar.Brand className={` nav-brand-main`} as={Link} to="/">
        <img
          src={logo}
          className={`d-inline-block align-top logo-nav-main ${isSticky ? "logo-stuck" : "logo-not-stuck"}`}
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav navbar-toggle-hamburger" onClick={() => setExpanded(!expanded)}></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto margin-l basic-navbar-main-menu">
          <div className="nav-wrapper">
            <NavDropdown title="HANSA-FLEX South Africa" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              {/* <NavDropdown title="Who we are" id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/mhp">MHP</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/shp">SHP</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/mso">MSO</NavDropdown.Item>

              </NavDropdown>
              <NavDropdown.Divider /> */}
              <NavDropdown.Item as={Link} to="/about-sa">Who We Are</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/management">Management</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/journey">Journey</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/certificates">Certificates</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/contact-us">Contact</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Approvalsnew">Approvals</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/careers">Careers</NavDropdown.Item> */}
              {/* <Nav.Link href="https://www.Hansa-Flex.de/en/careers/vacancies/" target="_blank" rel="noopener noreferrer" className='drop-menu-item-link' style={{ fontSize: '1rem', marginLeft: '10%', fontWeight: 400, paddingLeft: 0 }}>
                Careers
              </Nav.Link> */}
              {/* TODO: news and events , careers
              
              <NavDropdown.Item href="#action/3.2">Certifications</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Approvals</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">Management</NavDropdown.Item>*/}



            </NavDropdown>
            <div className="nav-line"></div>
          </div>
          <div className="nav-wrapper">
            <NavDropdown title="HANSA-FLEX Group" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>


              <NavDropdown.Item as={Link} to="/hf-group-management">Management</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-group-history">History</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-group-mission-statement">Mission Statement</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-60-years-of-hansaflex">60 Years of HANSA-FLEX</NavDropdown.Item>
              <Nav.Link href="https://www.Hansa-Flex.de/en/company/" target="_blank" rel="noopener noreferrer" className='drop-menu-item-link' style={{ fontSize: '1rem', marginLeft: '10%', fontWeight: 400, paddingLeft: 0 }}>
                More About HANSA-FLEX Group
              </Nav.Link>
              {/*<NavDropdown.Item href="#action/3.3">Certifications</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">60 years of HANSA-FLEX</NavDropdown.Item>*/}

            </NavDropdown>
            <div className="nav-line"></div>
          </div>
          <div className="nav-wrapper">
            {/* <NavDropdown title="Products" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/hoses-and-hose-lines">Hoses and Hose Lines</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hose-line-config">Hose Line Configurator</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hydraulic-hoses">Hydraulic Hoses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/metal-hoses">Metal Hoses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Industrial-hoses">Industrial Hoses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/performed-hoses">Pre-formed Hoses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ext-high-pfm-hoses">Extremely High Pressure Hoses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Ptfe-hoses">PTFE Hoses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hose-accessories">Accessories</NavDropdown.Item>
            </NavDropdown> */}


            <NavDropdown title="Products" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown title="Hoses and Hose Lines" id="nested-nav-dropdown" drop='end' >
                <NavDropdown.Item as={Link} to="/hoses-and-hose-lines">Hoses and Hose Lines</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/hose-line-config">Hose Line Configurator</NavDropdown.Item> */}
                <NavDropdown.Item as={Link} to="/hydraulic-hoses">Hydraulic Hoses</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/metal-hoses">Metal Hoses</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Industrial-hoses">Industrial Hoses</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/performed-hoses">Pre-formed Hoses</NavDropdown.Item> */}
                {/* <NavDropdown.Item as={Link} to="/ext-high-pfm-hoses">Extremely High Pressure Hoses</NavDropdown.Item> */}
                <NavDropdown.Item as={Link} to="/Ptfe-hoses">PTFE Hoses</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/hose-accessories">Accessories</NavDropdown.Item> */}
              </NavDropdown>

              {/* <NavDropdown title="Pipelines" id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/pipelines">Pipelines</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipebends">Pipebends</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipeend-processing">Pipe-end processing</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Expansionjoints">Expansion Joints</NavDropdown.Item>
              </NavDropdown> */}


              {/* <NavDropdown title="Hydraulic line tube" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Marinizedtube">Marinized tube</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Stainlesssteeltube">Stainless steel tube</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Steeltube">Steel tube</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Zistatube">Zista tube</NavDropdown.Item>
              </NavDropdown> */}

              <NavDropdown title='Hydraulic Connection technology' id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/hydraulic-connection-technology">Hydraulic Connection Technology</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-fitting">Hose Fittings</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Compression-fitting"> Compression Fittings</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/adapters">Adapters</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/coupling">Couplings</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ball-valves">Ball Valves</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/flanges">Flanges</NavDropdown.Item>
              </NavDropdown>



              <NavDropdown title="Hydraulic Components" id="nested-nav-dropdown" drop='end' >
                <NavDropdown.Item as={Link} to="/drive-and-control-tech">Hydraulic Componants</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pumps">Pumps</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-motor">Hydraulic Motors</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cylinder">Cylinders</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/switches-and-sensors">Switches and Sensors</NavDropdown.Item>
                {/* <NavDropdown title="Valve technology" id="nested-nav-dropdown" > */}
                <NavDropdown.Item as={Link} to="/valve-tech">Valve Technology</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/pipeline-installation-valve">Pipeline installation valves</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cartridge-valve">Cartridge valves</NavDropdown.Item> */}
                {/* <NavDropdown.Item as={Link} to="/cetop-valve">CETOP valves</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown.Item as={Link} to="/measuring-tech">Measuring technology</NavDropdown.Item> */}
                <NavDropdown.Item as={Link} to="/seven-hundred-hydraulics">700 Bar Heavy Load Hydraulics</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pressure-accumulators">Accumulators</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item as={Link} to="/hydraulic-line-tube">Hydraulic Line Tube</NavDropdown.Item>


              {/* <NavDropdown.Item as={Link} to="/sealing-tech">Sealing Technology</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/filtration">Filtration</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/pneumatics">Pneumatics</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/technical-information">Technical Information</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/approvals">Approvals</NavDropdown.Item> */}
              {/* <NavDropdown.Item as={Link} to="/catalogues">Catalogues</NavDropdown.Item> */}


            </NavDropdown>
            <div className="nav-line"></div>
          </div>


          <div className="nav-wrapper">
            <NavDropdown title="Services" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/mobile-rapid-hydraulic-service">Mobile Rapid Hydraulic Service</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/african-export-division">African Export Division</NavDropdown.Item>

              {/* <NavDropdown title="Fluid Management" id="nested-nav-dropdown" >
                <NavDropdown.Item as={Link} to="/fluid-service">Fluid management</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil-analysis">Oil Analysis</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil-filtering-and-cleaning">Oil Filtering and Cleaning</NavDropdown.Item>
              </NavDropdown> */}

              {/* <NavDropdown title="Assembly and Installation" id="nested-nav-dropdown" >
                <NavDropdown.Item as={Link} to="/assembly-and-installation">Assembly and Installation</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/modernisation-and-optimisation">Modernisation and optimisation</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/plant-project-planning-and-management">Plant project planning and management</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/installation-of-pipe-and-hoseline-systems">Installation of pipe and hose line systems</NavDropdown.Item>
              </NavDropdown> */}

              {/* <NavDropdown title="Preventative maintenance" id="nested-nav-dropdown" >
                <NavDropdown.Item as={Link} to="/preventative-maintainance">Preventative maintenance</NavDropdown.Item>
                <NavDropdown title="Hose Management" id="nested-nav-dropdown" >
                  <NavDropdown.Item as={Link} to="/hose-management">Hose management</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/x-code-hose-identification-system">X-CODE hose identification system</NavDropdown.Item>
                  <NavDropdown title="X-CODE Manager" id="nested-nav-dropdown" >
                    <NavDropdown.Item as={Link} to="/x-code-manager">X-CODE Manager</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/licenses">Licences</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Item as={Link} to="/service-packages">Service packages</NavDropdown.Item>
                </NavDropdown> */}
              {/* <NavDropdown title="Inspections" id="nested-nav-dropdown" >
                  <NavDropdown.Item as={Link} to="/inspection">Inspections</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dguv-inspection">DGUV inspection</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/industrial-hose-inspection">Industrial hose inspection</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/lifting-platforms-and-loading-bridges">Lifting platforms and loading bridges</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Pressureaccumulatorinspection">Pressure accumulator inspection</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown> */}

              {/* <NavDropdown title="Repair and overhaul" id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/repair-and-overhaul">Repair and overhaul</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/hydraulic-cyclinder-repair">Hydraulic Cylinder Repairs</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/pressure-accumulators-and-associated-systems">PressureAccumulatorsAndAssociatedSystems</NavDropdown.Item>
              </NavDropdown> */}

              {/* <NavDropdown title="Digital Services" id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/DigitalServices">Digital Services</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Scannersolutions">Scanner Solutions</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/EDIandBMEcat">EDI and BMEcat</NavDropdown.Item>
              </NavDropdown> */}

              {/* <NavDropdown title="Production" id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/Production">Production</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/SeriesProduction">Series Production</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/Customisedassembly">Customised assembly</NavDropdown.Item>
              </NavDropdown> */}

              <NavDropdown title="Logistics solution " id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Logisticssolutions">Logistics Solutions</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/Warehousemanagement">Warehouse management</NavDropdown.Item> */}
                <NavDropdown.Item as={Link} to="/Kanbandelivery">Kanban Delivery</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Kitting">Kitting</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Hydraulicworkshopcontainers">Hydraulic Workshop Containers</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Worksinworks">Works-In-Works</NavDropdown.Item>
              </NavDropdown>

              {/* <NavDropdown title="Engineering " id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/Engineering">Engineering</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/EngineeredbyHansaflex">Engineered by HANSA-FLEX</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/Hydraulicunit">Hydraulic Unit Manufacture</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/Prototypemanufacture">Prototype manufacture</NavDropdown.Item>
              </NavDropdown> */}

              {/* <NavDropdown.Item as={Link} to="/hydraulic-academy">Training and seminars</NavDropdown.Item> */}

            </NavDropdown>
            <div className="nav-line"></div>
          </div>


          <div className="nav-wrapper">
            <NavDropdown title="News and Events" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/electra-mining">Electra Mining 2024</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/training-news">Training Dates</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/empowering-youth">Empowering Youth</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/electra-mining-success">Electra Mining 2024 - A Resounding Success</NavDropdown.Item>

            </NavDropdown>
            <div className="nav-line"></div>

          </div>
          {/* <div className="nav-wrapper">
            <a href="" style={{ textDecoration: "none" }}><NavLink>Careers</NavLink></a>
            <Nav.Link href="https://www.Hansa-Flex.de/en/careers/vacancies/" target="_blank" rel="noopener noreferrer">
              Careers
            </Nav.Link>
            <div className="nav-line"></div>
          </div> */}


          <hr />
          <div className="additional-nav-mobile-menu">
            <Nav.Link href="https://shop.Hansa-Flex.za/en_GB/" target="_blank" rel="noopener noreferrer">
              <FaShoppingCart />  Online-Catalogue

            </Nav.Link>
            {/* <Nav.Link href="https://my.Hansa-Flex.za/en_GB/login" target="_blank" rel="noopener noreferrer">
              <FaCode />  X-CODE Manager

            </Nav.Link> */}
          </div>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainNavbarMenu;
